<template>
  <div>
    <customer-details-info
      v-if="showDetails"
      @redirect="redirectBack"
    />
    <ListingLayout
      :sub-screen="1"
      :is-loading="isLoading"
      :meta="meta"
      :per-page-options="perPageOptions"
      :headers="_headers"
      :items="list"
      no-filters
      :show-date="showDetails"
      :date-validation="false"
      @filterOption="onFiltersChange"
    >
      <template v-slot:start_soc="{ item }">
        <div>{{ item.start_soc }} %</div>
      </template>
      <template v-slot:end_soc="{ item }">
        <div>{{ item.end_soc }} %</div>
      </template>
      <template v-slot:duration="{ item }">
        <div>{{ item.duration }}</div>
      </template>
      <template v-slot:charge_gain="{ item }">
        <div>{{ item.charge_gain }} %</div>
      </template>
      <template v-slot:energy_consumption="{ item }">
        <div>{{ item.energy_consumption }}</div>
      </template>

      <!-- Action Modals -->
      <template #actionModals>
        <refresh-button
          v-if="list.length > 0 && showDetails"
          :is-loading="isLoading"
          @reload="fetchData"
        />
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";

export default {
  components: {
    CustomerDetailsInfo: () =>
      import("@/components/sections/CustomerDetailsInfo.vue"),
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],
  props: {
    showDetails: {
      type: Boolean,
      default() {
        return true;
      },
    },
    perPageOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      filters: {},
      subFilters: {},
      //
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicleChargeSession/getIsLoading",
      meta: "vehicleChargeSession/getMeta",
      list: "vehicleChargeSession/getChargeSessionList",
      //
      vehicle: "vehicles/getVehicle",
    }),
    _headers() {
      const head = [
        // bms_id column will show only for ecodost
        // ...(this.vehicle?.vehicle_model_type == "three_wheeler"
        //   ? [
        //       {
        //         text: this.$t("bms_id"),
        //         align: "center",
        //         sortable: true,
        //         value: "bms_id",
        //       },
        //     ]
        //   : []),

        {
          text: this.$t("session_id"),
          align: "center",
          sortable: true,
          value: "interval_id",
        },
        {
          text: this.$t("start_datetime"),
          align: "center",
          sortable: false,
          value: "start_datetime",
        },
        {
          text: this.$t("end_datetime"),
          align: "center",
          sortable: false,
          value: "end_datetime",
        },
        {
          text: this.$t("start_soc"),
          align: "center",
          sortable: false,
          value: "start_soc",
        },
        {
          text: this.$t("end_soc"),
          align: "center",
          sortable: false,
          value: "end_soc",
        },
        {
          text: this.$t("duration"),
          align: "center",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("charge_gain"),
          align: "center",
          sortable: false,
          value: "charge_gain",
        },
        {
          text: this.$t("energy_gain"),
          align: "center",
          sortable: false,
          value: "energy_consumption",
        },
        {
          text: this.$options.filters.currencyFormat(
            this.$t("monetary_cost"),
            this.authUser
          ),
          align: "center",
          sortable: false,
          value: "monetary_cost",
        },
        {
          text: this.$options.filters.currencyFormat(
            this.$t("electricity_per_unit_price"),
            this.authUser
          ),
          align: "center",
          sortable: false,
          value: "electricity_per_unit_price",
        },
      ];
      if (this.vehicle.battery_slot_type == "swappable") {
        head.unshift({
          text: this.$t("bms_id"),
          align: "center",
          sortable: true,
          value: "bms_id",
        });
      }
      return this.headers.length > 0 ? this.headers : head;
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    this.$store.commit("vehicleChargeSession/SET_VEHICEL_CHARGE_SESSION", []);
  },
  methods: {
    async fetchData() {
      if (this.showDetails) {
        const imei = this.$route.params.imei;
        this.options.imei = imei;
        await this.$store
          .dispatch("vehicleChargeSession/list", this.options)
          .then(() => {});
      } else {
        this.$emit("fetch", this.options);
      }
    },
    redirectBack() {
      const params = {
        product: this.$route.query.product ?? undefined,
        fleetId: this.$route.query.fleetId ?? undefined,
        oem_b2b_ids: this.$route.query.oem_b2b_ids ?? undefined,
        tenant_id: this.$route.query.tenant_id ?? undefined,
        search: this.$route.query.search ?? undefined,
        page: this.$route.query.page ?? undefined,
        perPage: this.$route.query.perPage ?? undefined,
      };
      return this.$router.push({
        name: "charge_session",
        query: params,
      });
    },
  },
};
</script>
